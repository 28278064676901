import React from "react"
import "./index.scss"

const PageWrapper = ({ color, maxWidth, children }) => {
  return (
    <div
      className={`page-wrapper ${maxWidth ? " max-width" : ""} ${
        color ? color : ""
      }`}
    >
      {maxWidth ? <div className="wrapper-wrapper">{children}</div> : children}
    </div>
  )
}

PageWrapper.defaultProps = {
  color: "",
  maxWidth: false,
}

export default PageWrapper
